<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard>
          <CapexFormulario
            :idTipoCapex="idTipoCapex"
            :tipoCapex="tipoCapex"
            :nombreTipoCapex="nombreTipoCapex"
            :viewVersions="1"
          >
          </CapexFormulario>
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'CapexCampoFormVerVersiones',
  components: {
    CapexFormulario: () => import('../../components/CapexFormulario')
  },
  data: () => ({
    title: 'VISUALIZAR VERSIÓN CAPEX CAMPO',
    page: {
      title: 'Visualizar Versión CAPEX Campo'
    },
    breadcrumbs: [
      {
        text: 'Presupuesto',
        disabled: true
      },
      {
        text: 'CAPEX Campo',
        disabled: true
      },
      {
        text: 'Visualizar Versión',
        disabled: false
      }
    ],
    idTipoCapex: 4,
    tipoCapex: 'camp',
    nombreTipoCapex: 'Campo'
  }),
  methods: {
    open() {},
    close() {}
  },
  created() {}
};
</script>
